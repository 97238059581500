$theme-colors: (
  'primary': #443792,
  'light': #F2F2F2,
  'secondary': #6563FF,
  'dark': #333333,
  'danger': #f44336
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';


.main-layout {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  // background-color: #F5F7FB;
}

.sidebar {
  height: 100vh;
  width: 18.3%;
  color: #ffffff;
  font-size: 14px;
  overflow-y: auto;
}

.sidebar-plain {
  height: 100vh;
  overflow-y: auto;
}


.dashboard {
  background-color: #F5F7FB;
  width: 81.7%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 20px;
}

.sidebar-card {
  margin-top: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  color: #FFF;
  text-align: center;
  // font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .btn {
    background-color: #f2f2f2;
    color: var(--primary, #443792);
  }

}

.id-wrapper {
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.10);
  padding: 7px 4px;
  color: #FFF;
  // font-family: Satoshi;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 220px;
  margin: 0 auto;
}

.navbar-notification {
  border-radius: 40px;
  background: #F7EEFF;
  padding: 6px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    font-size: 14px;
    margin: 0;
    color: #9B51E0;
  }
}


.custom-bordered {
  border-radius: 8px;
  border: 1px solid #E0E0E0;
}

.form-control {
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

.divider {
  border-bottom: 1px solid #F2F2F2;
}

.title {
  color: #828282;
  // font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.progress-container {
  // ul {
  //   list-style: none;

  //   li {
  //     span {
  //       display: block;
  //       font-size: 18px;
  //     }
  //   }
  // }
  .progress {
    height: 8px;
  }

  .progress-container-item {
    position: relative;
    padding: 7px;
    margin-top: 8px;
    margin-bottom: 8px;
    
    .progress {
      margin-left: 20px;
    }
    
    .value-stack {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      text-align: center;
    }
  }
}

.login-banner {
  overflow: hidden;
  display: flex;
  justify-content: end;
  img {
    // margin-left: 80px;
    width: 80%;
  }
}

.full-height {
  height: 100vh;
  // width: 100%;
}


/** Inputs */

input[type=text], input[type=email], input[type=password], input[type=tel], input[type=date], input[type=url], textarea {
  background-color: #F8F8F8 !important;
}

.input-group-btn-bg {
  background-color: inherit !important;
}


.profile-avatar {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  overflow: hidden;
  border: 2px dashed #443792;
  border-spacing: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECF3FD;
  color: #443792;

  img {
    width: 100%;
  }
}

.profile-avatar-plain {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  overflow: hidden;
  border: 1px solid #cccccc;
  border-spacing: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECF3FD;
  color: #443792;

  img {
    width: 100%;
  }
}

/** Verification steps */

.verification-steps {
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #ffffff;
      position: relative;
      margin-bottom: 40px;
      font-weight: 400;

      &::before {
        content: '';
        position: absolute;
        top: 65px;
        left: 17px;
        height: 50px;
        border: 1px dashed #ffffff;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }

      span {
        display: block;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        border-radius: 50%;
        color: #ffffff;
        margin-right: 30px;
      }
      small {
        display: block;
        font-weight: 400;
      }
    }
  }
}

.text-right {
  text-align: right;
}

.custom-file-select {
  display: block;
  text-align: center;
  background-color: $light;
  cursor: pointer;
  font-size: 14px;

  input {
    display: none;
  }
}

.font-bold {
  font-weight: 700;
}

.modal-profile {
  text-align: left;
  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      border-bottom: 1px solid #f2f2f2;
      &:last-child {
        border: none;
      }
      .icon {
        margin-right: 10px;
        margin-top: 10px;
      }
      small {
        display: block;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        width: 100%;
      }
    }

  }
}

.otp-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box {
    border: 1px solid #E0E0E0;
    border-radius: 9px;
    width: 40px;
    height: 40px;
    background: #ffffff !important;
    text-align: center;
  }
}

.table-color {
  th {
    color: #828282;
    font-weight: 600;
  }

  tr {
    border: none;
    color: #333333;
    font-size: 14px;
    
    p, strong {
      color: #828282;
      font-size: 14px;
      // font-weight: 400;
    }
    
    strong {
      font-weight: 600;
    }
    
    td {
      border: none;
      padding-top: 15px;
      padding-bottom: 15px;

      .badge {
        border-radius: 8px;
        padding: 9px;
        font-size: 12px;
      }
      
      .badge-active {
        background: #F0F8F0 !important;
        color: #008000;
      }
      
      .badge-failed {
        background: #FBF1F1 !important;
        color: #EB5757;
      }
      
      .badge-used {
        background: #f2f2f2 !important;
        color: #828282;
      }
      .dropdown-toggle:after {
        display: none;
       }
    }
  }
}

/** Pagination */
.pagination {
  align-items: center;
  margin: 0 auto;
  .active {
    color: var(--primary, #443792) !important;
    background-color: #ffffff;
  }
  .page-item {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-link {
      background-color: transparent;
      color: #828282;
      font-weight: 600;
      border: none;
      font-size: 14px;
    }
  }

  .page-item-prefix {
    font-size: 14px;
  }
}

.page-item-counter {
  color: #828282;
  font-size: 14px;
}

.tab-buttons {
  border: 1px solid #E0E0E0;
  color: #828282;

  &.active {
    background-color: #ffffff;
    color: var(--primary, #443792);
    border-color: transparent;
  }
}

.drews-badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drews-badge-success-circle {
  background-color: #E8F9E8;
  color: #219653;
}

.drews-badge-error-circle {
  background-color: #F9EAE8;
  color: #9F0A0A;
}


/** Data Card */
.data-card {
  position: relative;
  overflow: hidden;
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg--primary {
    .icon {
      background-color: #ECE9FF;
      color: var(--primary);
    }
    
    h5 {
      color: var(--primary);
    }
  }

  .bg--secondary {
    .icon {
      background-color: #F8E6FF;
      color: #BB6BD9; 
    }
    
    h5 {
      color: #BB6BD9;
    }
  }

  .bg--light {
    .icon {
      background-color: #f2f2f2;
      color: #4F4F4F; 
    }
    
    h5 {
      color: #4F4F4F;
    }
  }

  .bg--success {
    .icon {
      background-color: #ECF9EC;
      color: #008000; 
    }
    
    h5 {
      color: #008000;
    }
  }

  .bg--warning {
    .icon {
      background-color: #FDF5E7;
      color: #FAB941; 
    }
    
    h5 {
      color: #FAB941;
    }
  }

  .bg--danger {
    .icon {
      background-color: #FFF2F8;
      color: #EF5DA8; 
    }
    
    h5 {
      color: #EF5DA8;
    }
  }


  h5 {
    font-weight: 700;
    font-size: 22px;
  }
  p {
    color: #9B9B9B;
    font-size: 14px;
  }
}

.months-toggle {
  width: 150px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    width: 35px;
    background-color: #ffffff;
  }
}

.filter-button {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background-color: #ffffff;

  select {
    border: none;
    width: 80px;
    font-size: 14px;
    &:focus {
      box-shadow: none;
      outline: 0;
      border: none;
    }
  }
}

.badge-active {
  background-color: #F0F8F0 !important;
  color: #008000;
}


/** Chat container */
.chat-container {
  position: relative;
  width: 100%;
  height: 430px;
  overflow-y: auto;

  .input-group {
    border: 1px solid #E0E0E0;
    border-radius: 8px;  
    input {
      border: transparent;
    }
    .input-group-text {
      border: none !important;
    }
  }

  .chat-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: sticky;
    left: 0;
    bottom: 0;
    background-color: #ffffff;

    .name-tag {
      display: block;
      background-color: #443792;
      color: #ffffff;
      padding: 4px;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      margin-right: 20px;
    }
  }
}

::placeholder {
  font-size: 14px;
  color: #BDBDBD !important;
}
.text-sm {
  font-size: 14px;
}
.text-xs {
  font-size: 12px;
}
label {
  font-size: 14px;
}

.services {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .service-card {
    margin: 8px;
  }
}

.service-card {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 15px;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 280px;
  height: 257px;
  max-height: 257px;
  max-width: 280px;
  position: relative;

  p {
    color: #000;
    text-align: center;
    // font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    padding: 4px;
  }

  .btn {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 20px;
  }
}


.service-card .icon {
  .bg--primary {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ECE9FF;
      margin: 0 auto;
    }
  }
  

/* Customize Bootstrap Range Slider */
.custom-range::-webkit-slider-thumb {
  background-color: #443792; /* Change knob color */
  border: 1px solid #443792; /* Change knob border color */
  width: 20px;
  height: 20px;
}

.custom-range::-moz-range-thumb {
  background-color: #443792; /* Change knob color */
  border: 1px solid #443792; /* Change knob border color */
  width: 20px;
  height: 20px;
}

.custom-range::-webkit-slider-runnable-track {
  background-color: #EAE8F9; /* Change track color */
}

.custom-range::-moz-range-track {
  background-color: #EAE8F9; /* Change track color */
}

.custom-range::-moz-range-progress {
  background-color: #EAE8F9; /* Change track color */
}

.custom-range::-ms-fill-lower {
  background-color: #EAE8F9; /* Change track color */
}

.custom-range::-ms-fill-upper {
  background-color: #EAE8F9; /* Change track color */
}


.profile-photo {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}

.profile-photo-lg {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: 1px solid #828282;
  // overflow: hidden;
  position: relative;
  img {
    object-fit: cover;
  }

  .edit {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 1rem;
    padding: 0.5rem;
    z-index: 1;
    background-color: #443792;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;
    border: none;
    cursor: pointer;
  }
}

.custom-search-box {
  border: 1px solid #E0E0E0;
  border-radius: 8px;

  input {
    border: none !important;
    &:focus {
      box-shadow: none;
      outline: 0;
      border: none;
    }
  }
}

.rounded-14 {
  border-radius: 12px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-20 {
  border-radius: 20px;
}


/** Plans **/
.plan {
  h6 {
    color: #443792;
    font-weight: 700;
  }
  small {
    font-size: 12px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      // padding: 6px 0px 6px 0px;
      margin-top: 4px;
      margin-bottom: 4px;

      span {
        margin-right: 8px;
      }
    }
  }

  .pricing {
    width: 100%;
    color: #828282;

    p {
      font-weight: 500;
    }
    h5 {
      font-weight: 700;
    }

    .price-tags {
      font-size: 12px;
    }
  }
}

.bg---primary {
  background-color: #ECE9FF;
  color: #443792;
}

.bg---primary-light {
  background-color: lighten($color: #443792, $amount: 7);
}

.bg---primary-faint {
  // background-color: lighten($color: #443792, $amount: 47);
  background-color: rgba(68, 55, 146, 0.1);
}

.bg---secondary {
  background-color: #6563FF;
}

.bg---secondary-light {
  background-color: lighten($color: #6563FF, $amount: 7);
}

.bg---secondary-faint {
  background-color: lighten($color: #6563FF, $amount: 15);
}

.breadcrumb {
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // padding: 4px;
  // font-size: 16px;
  color: #9B9B9B;
  .active {
    color: #000;
  }
  a {
    text-decoration: none;
    color: #9B9B9B;
  }
}

.subscriptions {
  p {
    color: #828282;
  }
  ul {
    padding-left: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
      margin-bottom: 4px;
      padding: 6px;
      font-size: 14px;
      color: #4F4F4F;
    }
  }
}

.elipse {
  position: absolute;
  right: 0;
  top: 0;
}

.elipse-down {
  position: absolute;
  right: 0;
  bottom: 0;
}


.text--sm {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .px-5, .p5 {
    padding: 20px !important;
  }
  .sidebar {
    // display: none;
    position: absolute;
    left: 0;
    z-index: 1022 !important;
    width: 67%;
    // padding-top: 100px !important;
    // background-color: transparent !important;
  }
  .sidebar-logo  {
    // margin-top: 55px;
  }
  .dashboard {
    width: 100vw;
  }

  .half-screen-modal {
    width: 100% !important;
  }

  .receipt-screen-modal {
    width: 100% !important;
  }
}

.sidebar-mini {
  ul {
    list-style: none;
    li {
      button {
        color: #828282;
      }
      .active {
        color: #443792 !important;
      }
    }
  }
}

.button-active {
  background-color: #ECE9FF;
  color: #443792;
  border: none;
}

.half-screen-modal {
  width: 50%;
  margin-left: auto;  

  table thead tr th {
    font-weight: 400;
  }
}

.receipt-screen-modal {
  width: 35%;
  margin-left: auto;  

  table thead tr th {
    font-weight: 400;
  }
}

.receipt-anchor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ul {
    padding-left: 0;
  }

  ul li {
    list-style: none;
    font-size: 16px;

    .title {
      display: block;
      text-transform: capitalize;
      color: #C1BABA;
      margin-bottom: 7px;
    }
  }
}

.payment-link-bg {
  border: 1px dashed #828282;
  padding: 20px 10px;
  color: #828282;
  a {
    color: #828282;
    font-size: 20px;
  }
}

// .padding-horizontal {
//   padding: 50px !important;
// }


@media print {
  body {
    font-size: 14px;
  }

  /* Hide elements not meant for printing */
  .no-print {
    display: none;
  }

  .half-screen-modal {
    width: 100%;
  }

  .printarea {
    width: 100%;
  }
}

.print--bg {
  background-color: #443792;
  -webkit-print-color-adjust: exact; 
}

.btn-plain {
  border: none;
  background-color: transparent;

}

.active-btn {
  background-color: #443792 !important;
  color: #ffffff !important;
  font-weight: 700;
}


.notification-button {
  position: relative;
}

.notification-dropdown {
  position: absolute;
  right: -100px;
  top: 60px;
  z-index: 999;
  min-width: 400px;
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow:    0 0 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow:     0 0 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow:      0 0 10px rgba(0, 0, 0, 0.1);
  background-color: $white;
  padding: 15px;
  transition: all .5s ease-in;

  ul {
    padding-left: 0;
    list-style: none;
    li {
      border-bottom: solid .5px #828282;
      padding: 6px 0;
      .time {
        font-weight: 600;
        color: #443792;
      }
      button {
        text-align: left !important;
        font-size: 14px;
      }
    }
  }
}

.freeze {
  background-color: rgb(255 255 255 / 0.1);
  filter: blur(5px);
}

.logout-section {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.qr-img {
  svg {
    width: 100%;
  }
}

// .nav-avatar {
//   overflow: hidden;
//   height: 40px;
//   max-height: 40px;
//   width: 40px;
//   max-width: 40px;
//   display: inline-block;
//   img {
//     width: 100%;
//     object-fit: cover;
//   }
// }

.nav-avatar {
  overflow: hidden;
  height: 40px;
  max-height: 40px;
  width: 40px;
  max-width: 40px;
  display: inline-block;
  border-radius: 50%; /* Assuming you want a circular avatar */
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top; /* Set the object position to top */
  }
}

.thumbnail-image {
  height: 70px;
  width: 70px;
  max-height: 70px;
  max-width: 70px;
}


@media screen and  (max-width: 768px) {
  .service-card {
    padding: 15px;
    width: 100%;
    height: 257px;
    max-height: 257px;
    max-width: 100%;
  }
}


// System info

.info-header {
  padding: 5px;
  background-color: #443792;
  color: #fff;
  text-align: center;
}

.currency-wrapper {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  display: block;
  padding: 8px;
  min-width: 100px;
  max-width: 140px;
  font-size: 13px;
  color: #333;

  img {
    margin-right: 10px;
    border-radius: 8px;
    // max-width: 30px;
  }
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

select:focus {
  outline: 0;
  box-shadow: none;
}

.custom-divider {
  position: relative;

  .knob {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.new-menu {
  padding: 1.5px 6px;
  background-color: #FEF1E6;
  color: #F2994A;
  border-radius: 20px;
  border: 1px solid #F2994A;
  font-weight: 600;
}

// select {
//   &:focus, .form-control {
//     outline: 0;
//     box-shadow: none;
//   }
// }

select {
  &:focus {
    box-shadow: none !important;
  }
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}


.in-page-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  // background-color: rgba(255, 255, 255, 0.1);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.2;
}

.data-container {
  position: relative;
}

.bg--danger {
  background-color: #f44336; 
  background: #f44336; 
}

.product-details {
  ul {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 10px;
      // border-bottom: dashed 1px #9B9B9B;

      &::after {
        content: '';
        width: 100%;
        display: block;
        border: dashed 1px #9B9B9B;
        margin-top: 10px;
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Irish+Grover&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Irish+Grover&display=swap');

.voucher-pdf {
  padding-top: 150px;
}

.voucher-card {
    width: 700px;
    border: solid #333;
}

.voucher-card-row {
    display: flex;
    justify-content: flex-start;
}

.voucher-card-left {
    position: relative;
    // background-color: #76609F;
    color: #ffffff;
    width: 30%;
}

.voucher-card-left .ribbon-top {
    position: absolute;
    top: 10px;
    left: 0;
    opacity: .5;
}

.voucher-card-left .ribbon-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .5;
}

.voucher-card-right {
    background-color: #F5F5F5;
    width: 70%;
    position: relative;
    padding: 20px 40px;
}

.merchant-logo-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.voucher-card-right .merchant-logo {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    max-height: 70px;
    max-width: 70px;
    overflow: hidden;
    // border: 3px solid #76609F;
}

.voucher-card-right .merchant-logo img {
    width: 100%;
    border-radius: inherit;
}

.voucher-card-right .merchant-name {
    // border: 1px dashed #76609F;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 40px;
    font-size: 16px !important;
}

.voucher-card-right .customer-name {
    // background-color:#76609F;
    border-radius: 30px;
    color: #ffffff;
    margin-top: 10px;
    padding: 2px 10px;
}

.voucher-card-right .customer-name p {
    font-weight: 400;
    font-size: 12px;
    // background-color: #8C2121;
    margin-top: 8px;
    margin-bottom: 8px;
}

.voucher-card-right .customer-name span {
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 15px;
}

.voucher-card-right .ribbon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.paycode-box {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    width: 90%;
    padding: 10px 20px;
}

.paycode-header {
    padding-left: 30px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}

.paycode-box span {
    display: block;
    border-radius: 50%;
    border: 1px solid #76609F;
    font-weight: 600;
    text-align: center;
    height: 40px;
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    font-size: 20px;
    line-height: 42px;
    background-color: #ffffff;
    font-family: "Irish Grover", system-ui;
}

.paycode-box span:first-child {
    margin-left: 0;
}

.card-label {
    transform: rotate(-90deg);
    font-weight: 700;
    position: absolute;
    left: 5%;
    bottom: 50%;
    width: 100%;
}

.card-label p {
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 0;
    font-family: "Instrument Sans", sans-serif;
}

.card-label h1 {
    font-size: 27px;
    width: 100%;
    margin: 0;
    font-family: "Irish Grover", system-ui;
}

.voucher-card h1,h1,h3,h4,h5,h6 {
    margin: 0;
}

.text-center {
    text-align: center;
}

.text-danger {
    color: #8C2121;
}

.voucher-card-footer p {
    text-align: center;
    margin: 0;
    font-size: 10px;
}

.merchant-name-color {
    color: #76609F;
    font-weight: 600;
}